import '/src/scss/style.scss';
import 'vidstack/player/styles/default/theme.css';
import 'vidstack/player/styles/default/layouts/video.css';

if (import.meta.hot) {
	import.meta.hot.accept();
}

import { loadApp, loadModule } from '@spon/core';
import 'lazysizes';
import Header from '@/globals/Header';
import Modal from '@/globals/Modal';
import SetVh from '@/globals/SetVh';
import FadeIn from '@/globals/FadeIn';

import '@/plugins/logger';
import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';
import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';

webfontloader();

// load from data-behaviours
const app = loadApp(name => import(`./behaviours/${name}.js`), document.body); // eslint-disable-line

let headerModule = loadModule({
	module: Header,
	id: 'header',
	node: document.getElementById('header'),
	keepAlive: true,
});

loadModule({
	module: Modal,
	id: 'modal',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: SetVh,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: FadeIn,
	id: 'fadein',
	node: document.body,
	keepAlive: true,
});

detectTab();
activeLinks(window.location.pathname);
localLinks();

// Fade during loading. Dont need to remove as dom is updated
// https://htmx.org/events
if (typeof htmx !== 'undefined') {
	htmx.on('htmx:beforeSend', ({ target }) => {
		const results = document.getElementById('results');
		if (results) {
			results.classList.add('is-loading');
		}
	});

	htmx.on(
		'htmx:afterSettle',
		({
			target,
			detail: {
				requestConfig: { triggeringEvent },
			},
		}) => {
			console.log(target);

			localLinks(target);
			// We need to fire behaviours inside dynamically loaded blocks
			loadApp(name => import(`./behaviours/${name}.js`), target);

			if (triggeringEvent) {
				const { srcElement } = triggeringEvent;
				if (!srcElement) return;

				const shouldScroll =
					srcElement.closest('.c-pagination') || srcElement.dataset.scroll;
				if (srcElement && shouldScroll) {
					window.scrollTo({
						top: 0,
						behavior: 'smooth',
					});
				}
			}
		}
	);
}

document.addEventListener('afterBlitzInjectAll', event => {
	if (!headerModule) {
		headerModule = loadModule({
			module: Header,
			id: 'header',
			node: document.getElementById('header'),
			keepAlive: false,
		});
	}
});
