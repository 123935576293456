import MicroModal from 'micromodal';
import { eventBus } from '@spon/plugins';

function Modal() {
	MicroModal.init();

	eventBus.on('modal:open', id => {
		MicroModal.show(id);
	});
}

export default Modal;

/*
 *		Safari Bug
 *		https://github.com/ghosh/Micromodal/issues/496
 *		BEWARE: Safari is a piece of Shit; if you're trying `onShow: (modal, target, event) => {...` the `target` might not work, and you need to do `event.target` and maybe buble that up to the actual target. Urgh
 */
