import { withPlugins, eventBus, domEvents } from '@spon/plugins';
import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';
import gsap from 'gsap';

function Header({ node, name, plugins: { addEvents } }) {
	if (!node) return;

	// Constants
	const breakpoint = 100;
	const headroomOptions = {
		offset: node.clientHeight * 1.25 || 0,
		tolerance: { up: 5, down: 0 },
	};

	// Initialize Headroom
	const headroom = new Headroom(node, headroomOptions);

	// DOM Elements
	const $logo = node.querySelector('.c-header__logo');
	const $burgerBlob = node.querySelector('#burger-blob');
	const $menuBlob = node.querySelector('#menu-blob');
	const $navItems = [...node.querySelectorAll('.js-menu-li')];
	const $address = node.querySelector('.js-menu-address');

	// Event Listeners
	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());

	// Lock Scroll
	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `${-windowTop}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';
			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);
			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	// Navigation Toggle
	const nav = toggle({
		button: node.querySelector('.burger'),
		name,
		activeClass: 'is-active',
	});

	// Handle Clicks Outside Header
	function clickWhenOpenHandle({ target }) {
		if (!target.closest('#header')) nav.close();
	}

	// Open Navigation
	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('nav-open');
		target.classList.remove('invisible', 'h-0');
		target.classList.add('overflow-auto', 'visible', 'is-open', 'h-screen');

		$burgerBlob.classList.remove('translate-x-full', '-translate-y-full');
		$burgerBlob.classList.add('delay-1100');

		$menuBlob.classList.remove('menu-animate-out');
		$menuBlob.classList.add('menu-animate-in');

		node.classList.add('mix-blend-normal');

		gsap.fromTo(
			$navItems,
			{ opacity: 0, x: 80 },
			{
				opacity: 1,
				x: 0,
				duration: 0.5,
				stagger: 0.1,
				ease: 'easeOut',
				delay: 0.15,
			}
		);

		gsap.fromTo($address, { opacity: 0 }, { opacity: 1, delay: 1 });

		eventBus.emit('menu:open');
		lock.capture();
		document.addEventListener('click', clickWhenOpenHandle);
	});

	// Close Navigation
	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('nav-open');
		target.classList.add('invisible', 'h-0');
		target.classList.remove(
			'overflow-hidden',
			'visible',
			'h-screen',
			'is-open'
		);

		$burgerBlob.classList.add('translate-x-full', '-translate-y-full');
		$burgerBlob.classList.remove('delay-1100');

		$menuBlob.classList.add('menu-animate-out');
		$menuBlob.classList.remove('menu-animate-in');

		node.classList.remove('mix-blend-normal');

		gsap.set($navItems, { opacity: 0, x: 80 });

		eventBus.emit('menu:close');
		lock.release();
		document.removeEventListener('click', clickWhenOpenHandle);
	});

	// Handle Escape Key
	function onKeyDown(event) {
		if (event.key === 'Escape' && nav.isOpen) {
			nav.close();
		}
	}

	// Handle Primary Clicks
	function onPrimaryClick(event) {
		if (window.innerWidth < breakpoint) return;
		event.preventDefault();

		if (event.target.classList.contains('collapsed')) {
			headerBackground.style.height = '';
		} else if (window.innerWidth >= breakpoint) {
			const acc = event.target.nextElementSibling;
			if (!acc) return;

			const { top } = acc.getBoundingClientRect();
			const height = acc.scrollHeight;

			headerBackground.style.height = `${headerBackground.offsetHeight}px`;
			headerBackground.style.height = `${top + height}px`;
		}
	}

	// Reveal Logo
	$logo.classList.remove('hidden');

	// Initialize
	nav.init();
	headroom.init();

	// Add Events
	addEvents({
		'click [data-nav-primary]': onPrimaryClick,
	});

	addEvents(document.body, {
		click: ({ target }) => {
			if (!target.closest('#header')) nav.close();
		},
		keydown: onKeyDown,
	});

	// Cleanup
	return () => {
		navAccordions.forEach(item => item.dispose());
	};
}

export default withPlugins(domEvents)(Header);
