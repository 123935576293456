import { withPlugins, inview } from '@spon/plugins';

function FadeIn({ node, plugins: { inview } }) {
	/*
	 *		Variables
	 */

	inview.settings = {
		rootMargin: '0px 0px -40% 0px',
	};

	// watch some other nodes
	inview.observe([...node.querySelectorAll('[data-fade-in]')], {
		enter: ({ target }) => {
			if (!target.classList.contains('has-moved')) {
				target.classList.add('has-moved'); // handled with CSS.
			}
		},
	});
}
export default withPlugins(inview)(FadeIn);
